<template>
    <div>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="#4480C5"
        spinner="bar-fade-scale"
      />
      <v-layout wrap justify-center my-6>
        <v-flex pt-5 xs11 sm11 md11 lg11 xl11>
          <!-- table Section -->
  
          <v-layout wrap justify-start>
            <v-flex xs12 sm12 md12 lg12>
              <v-card class="pa-3" elevation="3">
              
  
<!--   
                <v-layout wrap px-lg-3 px-3>
                  <v-flex xs12 sm4 md4 lg4 >
                    <v-layout wrap align-center justify-start>
                    <v-avatar size="55" color="primary">
                      <v-icon large color="white">mdi-account</v-icon>
                    </v-avatar>
  
                 
                    <div class="ml-3">
                      <h3 class="holymasshdes" style="font-size: 18px">
                        {{ subadminlist.name }}
                      </h3>
                      <small class="text-des">Sub Admin</small>
                    </div>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm8 md8 lg8>
                    <v-layout wrap align-center justify-end>
                    <div class="my-2 ml-lg-3 ml-md-3 ml-sm-3 ml-0">
                      <v-icon color="primary" size="20">mdi-phone</v-icon> :
                      <span class="ml-lg-2 ml-sm-2 ml-md-2 ml-0 live-des">{{
                        subadminlist.phoneNumber
                      }}</span>
                    </div>
                    <div class="my-2 ml-lg-3 ml-md-3 ml-sm-3 ml-3 live-des">
                      <v-icon color="primary" size="20">mdi-email</v-icon> :
                      <span class="ml-2">{{ subadminlist.email }}</span>
                    </div>
                    </v-layout>
                  </v-flex>
                </v-layout> -->


                <v-layout wrap justify-space-between align-center>
                  <!-- Left Side: Avatar, Name, and Title -->
                  <v-flex xs12 sm4 md6 lg6 px-lg-2 px-md-2 px-sm-2 px-2>
                    <v-layout align-center>
                      <v-avatar size="55" color="primary">
                        <v-icon large color="white">mdi-account</v-icon>
                      </v-avatar>
                
                      <!-- Name and Title -->
                      <div class="ml-3">
                        <h3 class="holymasshdes" style="font-size: 18px">
                          {{ subadminlist.name }}
                        </h3>
                        <small class="text-des">Parish Manager</small>
                      </div>
                    </v-layout>
                  </v-flex>
                
                  <!-- Right Side: Phone and Email -->
                  <v-flex xs12 sm8 md6 lg6 px-lg-4 px-md-4 px-sm-4 px-4>
                    <v-layout wrap justify-end>
                      <div class="my-2 ml-4">
                        <v-icon color="primary" size="20">mdi-phone</v-icon> :
                        <span class="ml-2 live-des">{{ subadminlist.phoneNumber }}</span>
                      </div>
                      <div class="my-2 ml-3 live-des">
                        <v-icon color="primary" size="20">mdi-email</v-icon> :
                        <span class="ml-2">{{ subadminlist.email }}</span>
                      </div>
                    </v-layout>
                  </v-flex>
                </v-layout>
  
                
  
                <v-card-text>
                  <!-- Display manager details -->
  
                  <v-divider class="my-3"></v-divider>
  
  
                  <!-- Data Table for churches -->
                  <template v-if="churchArray.length > 0">
                    <v-data-table
                    :headers="headers"
                    :items="churchArray"
                    item-key="name"
                    class="elevation-1 text--black"
                    id="virtual-scroll-table"
                   style="cursor: pointer;"
                    hide-default-footer
                  >
                    <template v-slot:body="{ items }">
                      <tbody>
                        <tr v-for="item in items" :key="item._id" @click="redirectToViewPage(item)">
                          <td>{{ item.name }}</td>
                          <td>
                            {{ item.address.city }}, {{ item.address.state }}, {{ item.address.country }}
                          </td>
                          <td>{{ item.email }}</td>
                          <td>
                           <span style="color:red; font-family:intermedium; font-size: 14px;"  v-if="item.status === 'Pending'">Pending</span> 
                           <span style="color:green; font-family:intermedium; font-size: 14px;"  v-else>Approved</span> 
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                  
                  </template>
                  <template v-else>
                    <v-flex
                      xs12
                      text-center
                      py-5
                      style="
                        font-family: intersemibold;
                        color: grey;
                        font-size: 16px;
                      "
                    >
                      No Churches Found
                    </v-flex>
                  </template>
                </v-card-text>
  
                <!-- <v-card-actions>
                    <v-btn block color="primary" @click="viewProfile">
                      View Full Profile
                    </v-btn>
                  </v-card-actions> -->
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-dialog v-model="approvedialog" max-width="600">
        <v-card class="dialog-card">
          <v-card-title class="grey lighten-2 d-flex justify-center">
            <v-icon color="red" size="32">mdi-alert</v-icon>
            <span class="ml-2">Confirm Approval</span>
          </v-card-title>
          <v-card-text class="py-5 text-center text-des">
            <div class="body-1">
              Are you sure you want to Approve Church?
              <br />
              This action <strong>cannot be undone</strong>.
            </div>
            <v-divider class="my-3"></v-divider>
           
          </v-card-text>
          <v-card-actions class="d-flex justify-center pa-2 py-6">
            <v-btn
              style="font-family: interregular; font-size: 13px; color: white"
              color="#cf3a45"
              @click="approvedialog = false"
              >Cancel</v-btn
            >
            <v-btn
              style="font-family: interregular; font-size: 13px; color: white"
              color="blue darken-1"
              @click="confirmApprove"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
    
    <script>
  import axios from "axios";
  //import { search } from 'core-js/fn/symbol';
  export default {
    data() {
      return {
        // search: "",
  
        search1: "",
  
        // dialog: false,
        // userArray: [],
         churchId: [],
         churchArray: [],
        // user: [],
        // role: "user",
        itemToConfirm: null,
        approvedialog: false,
        adddialog: false,
        appLoading: false,
        msg: "",
        showSnackBar: false,
        ServerError: false,
        subadminlist: [],
        //subadminchurch: [],
        headers: [
          { text: "Church Name", value: "name", width: "200px" },
          { text: "Address", value: "address", width: "200px" },
         // { text: "Address", value: "address", width: "200px" },
          { text: "Email", value: "email", width: "100px" },
          { text: "Actions", value: "_id", width: "100px" },
        ],
        // churchArray: [],
        // church: "",
        // headers: [
        //   { text: "Name", value: "name", width: "200px" },
        //   { text: "Email", value: "email", width: "200px" },
        //   { text: "Phone", value: "phoneNumber", width: "50px" },
        //   // { text: "Role", value: "role", width: "50px" },
  
        //   // { text: "Actions", value: "_id", width: "5px", }
        // ],
      };
    },
    mounted() {
      this.getData();
      //   this.getParishManagers();
      this.getChurch();
    },
    watch: {
      // search(val) {
      //   this.loading = true;
      //   setTimeout(() => {
      //     this.getData(val);
      //     this.loading = false;
      //   }, 500);
      // },
    //   search1(val) {
    //     this.loading = true;
    //     setTimeout(() => {
    //       this.getChurch(val);
    //       this.loading = false;
    //     }, 500);
    //   },
      // church() {
      //   this.getChurch();
      //      },
    },
    methods: {
      redirectToViewPage(item) {
    this.$router.push('/churchView?id=' + item._id);
  },
      getChurch() {
        this.appLoading = true;
        
        //   const currentTime = new Date().toISOString(); // Get current time in ISO format
        axios({
          url: "/subadmin/churches",
  
          method: "GET",
          headers: {
            token: localStorage.getItem("token"),
          },
          params: {
          id: this.$route.query.id,
        
        },
        })
          .then((response) => {
            this.appLoading = false;
            this.churchArray = response.data.data;
            // this.pages = response.data.pages;
            // this.totalData = response.data.count;
            //this.pages = Math.ceil(response.data.totalLength / this.limit);
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },

      getData() {
        const data = {
          id: this.$route.query.id,
        };
        this.appLoading = true;
        axios({
          url: "/subadmin/profile/view",
          method: "post",
          // params: {
          //   // role: this.role, // Pass the role parameter here
          //   // keyword: this.search,
          //   id:this.$route.query.id,
          // },
          data: data,
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.appLoading = false;
            this.subadminlist = response.data.data;
            //this.subadminchurch = response.data.managedChurches;
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      openApproveDialog(item) {
        this.itemToConfirm = item;
        this.approvedialog = true;
      },
      confirmApprove() {
  if (this.itemToConfirm) {
    this.approveItem(this.itemToConfirm);
  }
  this.approvedialog = false;
},
approveItem(r) {
  const data = {
    id: r._id,
  };

  axios({
    url: "/subadmin/church/approve",
    method: "POST",
    data: data,
    headers: {
      token: localStorage.getItem("token"),
    },
  })
    .then((response) => {
      this.appLoading = false;
      if (response.data.status) {
        this.msg = response.data.message;
        this.showSnackBar = true;
        // Update status locally after approval
        const approvedChurch = this.churchArray.find(church => church._id === r._id);
        if (approvedChurch) {
          approvedChurch.status = 'Active'; // Update the status
        }
      } else {
        this.msg = response.data.message;
        this.showSnackBar = true;
      }
    })
    .catch((err) => {
      this.appLoading = false;
      this.ServerError = true;
      console.log(err);
    });
},
    },
  };
  </script>
    
    